<template>
    <div v-if="service" class="serviceContainer">
        <p v-html="service.title" class="title"></p>
        <img :src="service.imgUrl" alt="" class="backImg">
        <div class="backdrop"></div>
        <button class="transpBtn" @click="toggleModal">
            <!-- <span class="material-icons-outlined">close</span> -->
            <span class="material-icons-outlined">code</span>
        </button>
    </div>
    <modal v-if="modalOpen" class="serviceModal animate__animated"
        :class="openAnimation ? 'animate__zoomIn animate__faster' : 'animate__zoomOut'">
        <button class="transpBtn modal" @click="toggleModal">
            <!-- <span class="material-icons-outlined">close</span> -->
            <span class="material-icons-outlined">close</span>
        </button>
        <p class="modalTitle" v-html="service.title"></p>
        <p class="description" v-if="serviceText" v-html="serviceText"></p>
        <router-link :to="{ name: 'contactUs' }" class="router">
            <button class="btn contactUs">Contattaci</button>
        </router-link>
    </modal>
</template>

<script>
import axios from 'axios';
export default {
    props: ["service"],
    data() {
        return {
            modalOpen: false,
            openAnimation: true,
            serviceText: null,
        }
    },
    mounted() {
        this.fetchParagraph()
    },
    methods: {
        fetchParagraph() {
            axios
                .get(`./texts/${this.service.description}`)
                .then((response) => {
                    this.serviceText = response.data;
                })
                .catch((error) => {
                    console.error('Error fetching paragraph content:', error);
                });
        },
        toggleModal() {
            if (this.modalOpen === false) {
                setTimeout(() => {
                this.modalOpen = true
                this.openAnimation = true
                }, 200);
            }
            else if (this.modalOpen === true) {
                setTimeout(() => {
                this.openAnimation = false
                }, 200);
                setTimeout(() => {
                    this.modalOpen = false
                }, 500);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import url('@/assets/Style/serviceComponent.scss');
</style>