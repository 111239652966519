<template>
    <div class="background" id="Services"></div>
    <div class="gridcontainer">
        <serviceComponent v-for="s in services" :key="s.id" :service="s"></serviceComponent>
    </div>
</template>

<script>
import serviceComponent from "@/components/service-component.vue"
export default {
    components: {
        serviceComponent
    },
    data() {
        return {
            services: [
                { id: 1, title: "Siti & Portali Web", imgUrl: require("@/assets/images/service_WD.jpg"), description: 'SitiPortali.html' },
                { id: 2, title: "Brand identity", imgUrl: require("@/assets/images/service_Loghi.jpg"), description: 'LoghiBrandIdentity.html' },
                { id: 3, title: "Web marketing</br>SEO", imgUrl: require("@/assets/images/service_CopySEO.jpg"), description: 'CopywritingSEO.html' },
                { id: 4, title: "Foto & Video", imgUrl: require("@/assets/images/service_Foto.jpg"), description: 'FotoVideo.html' }
            ],
        };
    },

}
</script>

<style lang="scss" scoped>
@import url('@/assets/Style/servicesView.scss');
</style>