<template>
  <div class="Section CompanyIntro">
    <p class="companyDescription">Sviluppiamo la tua presenza online linea di codice dopo linea di codice <span
        class="pulse">_</span></p>
    <p class="companyName">Ethos Web Studio</p>
    <div class="sphere" id="external">
      <div class="sphere" id="middle">
        <div class="sphere" id="internal"></div>
      </div>
    </div>
  </div>
  <div class="Section ServicesIntro">
    <p class="WhatWeDo" v-html="viewPort < 768 ? WhatWeDo_text : WhatWeDo_text_Desktop"></p>
    <p class="WhereWeAre" v-html="WhereWeAre_text"></p>
    <div class="services scrollSnap">
      <serviceComponent v-for="s in services" :key="s.id" :service="s"></serviceComponent>
    </div>
    <router-link :to="{ name: 'services' }" class="router">
      <button class="btn">Scopri i servizi</button>
    </router-link>
  </div>
  <div class="Section ChiSiamo">
    <div class="GioTeo">
      <p class="text title">Ma chi siamo?<span class="pulse">_</span></p>
      <p class="text desc" v-html="ChiSiamoDesc_text"></p>
      <div class="imageContainer">
        <img class="avatar" :src="avatar_TEO" draggable="false" alt="avatar of Matteo" id="Matteo">
        <img class="avatar" :src="avatar_GIO" draggable="false" alt="avatar of Giosuè" id="Giosue">
      </div>
    </div>
  </div>
  <div class="Section Progetti">
    <p class="text title">Progetti<span class="pulse">_</span></p>
    <p class="text desc" v-html="ProjectsDesc_text"></p>

    <swiper v-if="viewPort >= 920" :grabCursor="true" :effect="'creative'" :creativeEffect="{
      prev: {
        shadow: true,
        translate: ['-120%', 0, -500],
      },
      next: {
        shadow: true,
        translate: ['120%', 0, -500],
      },
    }" :modules="modules" :pagination="{ dynamicBullets: true }" :loop="true" :navigation="true" class="mySwiper2">
      <swiper-slide v-for="p in projects" :key="p.id">
        <div class="swiperFullImg" :style="'background-image: url(' + p.imgUrl + ');'"></div>
      </swiper-slide>
    </swiper>

    <div v-else-if="viewPort < 920" class="projects scrollSnap">
      <projectComponent v-for="p in projects" :key="p.id" :project="p"></projectComponent>
    </div>
  </div>
  <div class="Section LavoriamoInsieme">
    <p v-if="viewPort < 1250" class="title">Lavoriamo insieme<span class="pulse">_</span></p>
    <div v-if="viewPort >= 1250" class="imageContainer">
      <img class="avatar" src="@/assets/images/avatar_TEO_big.png" draggable="false" alt="avatar of Matteo" id="Matteo">
      <img class="avatar" src="@/assets/images/avatar_GIO_big.png" draggable="false" alt="avatar of Giosuè" id="Giosue">
    </div>
    <p v-if="viewPort >= 1250" class="title" id="LetsWork">Lavoriamo</p>
    <p v-if="viewPort >= 1250" class="title" id="Togheter">Insieme<span class="pulse">_</span></p>
    <img :src="imgVector" draggable="false" alt="arrow going for the contactUs button" class="imgVector">
    <router-link :to="{ name: 'contactUs' }" class="router">
      <button class="sphereButton">
        <span class="desc">Parlaci del tuo progetto</span>
      </button>
    </router-link>
    <div class="contacUs">
      <a href="tel:+393881242054" ref="phoneLink" id="phoneLink"><button class="btn-contact">
          T. (+39) 388 1242054
        </button></a>
      <a href="mailto:ethoswebstudio@gmail.com" ref="mailLink" id="mailLink"><button class="btn-contact">
          ethoswebstudio@gmail.com
        </button></a>
    </div>
    <div class="infoContainer">
      <img src="@/assets/images/logo.png" draggable="false" alt="Company logo" class="Logo">
      <!-- <span class="endingText" id="nome"><b>Ethos web studio</b></span> -->
      <span class="endingText" id="details">2023 Ethos web studio</span>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-creative';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { EffectCreative, Pagination, Navigation } from 'swiper';
import serviceComponent from "@/components/homeservice-component.vue";
import projectComponent from "@/components/project-component.vue";
import { commonModule } from '@/commonModule.js';
export default {
  name: "HomeView",
  mixins: [commonModule],
  components: {
    serviceComponent,
    projectComponent,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [EffectCreative, Pagination, Navigation],
      avatar_TEO: require("@/assets/images/avatar_TEO.png"),
      avatar_GIO: require("@/assets/images/avatar_GIO.png"),
      imgVector: require("@/assets/images/Vector.png"),
      viewPort: window.innerWidth,
      mailWidth: "width: 200px",
      WhatWeDo_text: "Web Studio.</br>Siti,</br>Grafiche,</br>Brand identity",
      WhatWeDo_text_Desktop: "Web Studio.</br>Siti, Grafiche, Brand identity",
      WhereWeAre_text: "Situati a Padova,</br>offriamo servizi in tutta Italia.",
      ChiSiamoDesc_text: "<b>Ethos Web Studio</b> nasce con l’intento di <b>rivoluzionare</b> il mercato di creazione di <b>siti web</b> e <b>grafiche</b>.</br>L’obiettivo è quello di <b>aiutare</b> le persone ed aziende a creare una <b>presenza online</b> efficace e di successo.</br>Siamo orgogliosi del nostro lavoro e ci impegniamo a fornire ogni giorno <b>servizi di alta qualità</b> ai nostri clienti.",
      ProjectsDesc_text: "Esplora i nostri progetti più recenti.<br/>Fai un viaggio nel nostro mondo.",
      services: [
        { id: 1, title: "Web Design", desc: "Progettare siti non è una cosa “statica”.<br/>Animazioni, interazioni fruibilità...fanno la differenza", imgUrl: require("@/assets/images/green_sphere.png") },
        { id: 2, title: "Art Design", desc: "Creiamo esperienza di navigazione ottimale, con una forte attenzione all'estetica, alla funzionalità e facilità d'uso.", imgUrl: require("@/assets/images/yellow_sphere.png") },
        { id: 3, title: "Brand Identity", desc: "Aiutiamo le aziende a sviluppare una presenza distintiva e coerente attraverso l'uso di elementi grafici unici e accattivanti.", imgUrl: require("@/assets/images/blue_sphere.png") },
      ],
      projects: [
        { id: 1, name: "LaBottegaDelCicchetto", imgUrl: require("@/assets/images/project_LBDC.png") },
        { id: 2, name: "LaBottegaDelCicchetto", imgUrl: require("@/assets/images/project_LBDC.png") },
        { id: 3, name: "LaBottegaDelCicchetto", imgUrl: require("@/assets/images/project_LBDC.png") },
        { id: 4, name: "LaBottegaDelCicchetto", imgUrl: require("@/assets/images/project_LBDC.png") },
        { id: 5, name: "LaBottegaDelCicchetto", imgUrl: require("@/assets/images/project_LBDC.png") },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import url(@/assets/Style/home.scss);
@import url(@/assets/Style/home.css);
@import url(@/assets/Style/homeDesktop.scss);
@import url(@/assets/Style/homeDesktop.css);
@import url(@/assets/Style/swiperModule.css);
</style>
