<template>
  <div class="logoMenu animate__animated animate__fadeIn" :class="menuOpened === false && this.$route.name==='home'? 'backBlur' : ''">
    <router-link to="/" class="router" @click="menuOpened ? toggleMenu() : ''">
      <img :src="logoUrl" draggable="false" alt="Logo of the company, a styled 'E' letter, reppresenting the name Ethos"
        class="CompanyLogo">
    </router-link>
    <nav v-if="viewPort >= 768" class="navMenu">
      <router-link :to="{ name: 'services' }" class="navItem underline">Servizi</router-link>
      <!-- <router-link :to="{ name: 'WIP' }" class="navItem">Chi siamo</router-link> -->
      <router-link :to="{ name: 'WIP', params: { id: 'Progetti' } }" class="navItem underline">Progetti</router-link>
      <router-link :to="{ name: 'contactUs' }" class="navItem">
        <button class="btn" alt="Contattaci">Contattaci</button>
      </router-link>
    </nav>
    <label v-if="viewPort < 768" class="hamburger">
      <input type="checkbox" id="menuIcon" :checked="menuOpened" ref="menuIcon" @click="toggleMenu()">
      <svg viewBox="0 0 32 32">
        <path class="line line-top-bottom"
          d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22">
        </path>
        <path class="line" d="M7 16 27 16"></path>
      </svg>
    </label>
  </div>
  <div v-if="viewPort < 768 && menuOpened === true" class="menuMobile animate__animated"
    :class="closingAnimation === true ? 'animate__fadeOutUp' : 'animate__fadeInDown'">
    <div class="logoMenu"></div>
    <ul class="vociMenu">
      <!-- successivamente saranno integrati con i router-links per la navigazione tra pagine -->
      <li>
        <router-link :to="{ name: 'services' }" class="router" @click="toggleMenu(true)">
          <p class="voceMenu">Servizi</p>
        </router-link>
      </li>
      <!-- <li>
        <router-link :to="{ name: 'WIP' }" class="router" @click="toggleMenu(true)">
          <p class="voceMenu">Chi siamo</p>
        </router-link>
      </li> -->
      <li>
        <router-link :to="{ name: 'WIP', params: { id: 'Progetti' } }" class="router" @click="toggleMenu(true)">
          <p class="voceMenu">Progetti</p>
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'contactUs' }" class="router" @click="toggleMenu(true)">
          <p class="voceMenu">Contattaci</p>
        </router-link>
      </li>
    </ul>
  </div>
  <router-view />
</template>

<script>
import "animate.css";
export default {
  components: {
  },
  data() {
    return {
      viewPort: window.innerWidth,
      menuOpened: false,
      closingAnimation: false,
      logoUrl: require("./assets/images/logoSmall.png"),
    };
  },
  methods: {
    onResize() {
      this.viewPort = window.innerWidth;
    },
    toggleMenu(voceMenu = false) {
      if (this.menuOpened === false) {
        this.menuOpened = true
      } else if (this.menuOpened === true) {
        if (voceMenu === true) {
          setTimeout(() => {
            this.closingAnimation = true;
          }, 250);
        } else {
          this.closingAnimation = true;
        }
        setTimeout(() => {
          this.menuOpened = false
          this.closingAnimation = false;
        }, 650);
      }
    },
    changeLogo(size) {
      if (size < 768) {
        this.logoUrl = require("@/assets/images/logoSmall.png")
      } else if (size >= 768) {
        this.logoUrl = require("@/assets/images/logo.png")
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.changeLogo(this.viewPort)
  },
  watch: {
    viewPort: function (value) {
      this.changeLogo(value);
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  }
}
</script>


<style lang="scss">
@import url(./assets/Style/menu.scss);
@import url(./assets/Style/menu.css);
@import url(./assets/Style/global.scss);
@import url(./assets/Style/global.css);

body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  overflow: hidden auto;
  height: 100dvh;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>