import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import WorkInProgress from "../views/WIPView.vue"
import ContactUs from "../views/ContactUsView.vue"
import Services from "../views/ServicesView.vue"
import NotFound from "../views/NotFoundView.vue"

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/wip/:id",
    name: "WIP",
    component: WorkInProgress,
    props: true,
  },
  {
    path: "/ContactUs",
    name: "contactUs",
    component: ContactUs,
  },
  {
    path: "/Services",
    name: "services",
    component: Services,
  },
  //redirect
  {
    path: "/wip",
    redirect: "/"
  },
  // 404
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
