<template>
    <div class="service">
        <div class="grid">
            <img :src="service.imgUrl" draggable="false" alt="Little sphere with waves inside" class="sphereImg">
            <p class="title">{{ service.title }}</p>
            <p class="desc" v-html="service.desc"></p>
        </div>
    </div>
</template>

<script>
export default {
    props: ["service"]
}
</script>

<style lang="scss" scoped>
@import url("@/assets/Style/service.scss");
@import url("@/assets/Style/service.css");
</style>