<template>
    <div class="wave-group">
        <textarea v-if="multiline === true" @keyup="updateRows" ref="inputArea" required="true" :id="id" :rows="rows"
            class="input scrollSnap" :placeholder="placeholder"></textarea>
        <input v-else required="true" type="text" :id="id" :ref="id" :placeholder="placeholder" class="input">
        <span class="bar"></span>
        <!-- <label class="label">
            <template v-for="(char, index) in name" :key="index">
                <template v-if="char === ' '">
                    &nbsp;
                </template>
                <template v-else>
                    <span class="label-char" :style="'--index:' + index">{{ char }}</span>
                </template>
            </template>
        </label> -->
    </div>
</template>
  
<script>
export default {
    props: ['name', 'placeholder', 'id', 'multiline', 'rows'],
    data() {
        return {
            newLine: 70,
        }
    },
    methods: {
        updateRows() {
            /* if (window.innerWidth < 768) { */
            if (this.$refs.inputArea.textLength > this.newLine) {
                this.$refs.inputArea.rows = (this.$refs.inputArea.textLength / this.newLine) * 2.75
                if (((this.$refs.inputArea.textLength / this.newLine) * 2.75) > 5) this.$refs.inputArea.rows = 5
            }
            else this.$refs.inputArea.rows = 2
            /* } */
        }
    }
};
</script>
  
<style lang="scss" scoped>
.wave-group {
    position: relative;

    .input {
        font-size: 1rem;
        padding: 5px;
        padding-bottom: 8px;
        display: block;
        min-width: 30ch;
        border: none;
        border-bottom: 1px solid var(--darkGrey);
        background: transparent;
        letter-spacing: .03em;
        font-family: var(--fontFamily);

        &:focus {
            outline: none;
        }


    }

    ::placeholder {
        font-size: 1.15rem;
        font-weight: 500;
        color: var(--avorio);
        font-family: var(--fontFamily);
        text-align: left;
        z-index: 2;
        letter-spacing: 0.05em;
        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.40);
        position: absolute;
        pointer-events: none;
        left: 5px;
        /* top: 10px; */
        display: flex;
        height: fit-content;
    }

    .label-char {
        transition: 0.2s ease all;
        transition-delay: calc(var(--index) * 0.025s);
    }

    .bar {
        position: relative;
        display: block;
        width: 100%;

        &:before,
        &:after {
            content: '';
            height: 2px;
            width: 0;
            bottom: 1px;
            position: absolute;
            background: var(--avorio);
            transition: 0.2s ease all;
            -moz-transition: 0.2s ease all;
            -webkit-transition: 0.2s ease all;
        }

        &:before {
            left: 50%;
        }

        &:after {
            right: 50%;
        }
    }
}

.wave-group .input:focus~label .label-char,
.wave-group .input:valid~label .label-char {
    transform: translateY(-20px);
    font-size: 14px;
    color: var(--avorio);
}

.wave-group .input:focus~.bar:before,
.wave-group .input:focus~.bar:after {
    width: 50%;
}

@media (min-width: 768px) {
    .wave-group {
        width: 80%;

        .input {
            width: 100%;
        }
    }
}
</style>
  