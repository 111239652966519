export const commonModule = {
    mounted() {
        this.$nextTick(() => {
            window.addEventListener("resize", this.onResize);
        });
        this.changeLogo(this.viewPort);
    },
    methods: {
        onResize() {
            this.viewPort = window.innerWidth;
        },
        changeLogo(size) {
            if (size < 1250) {
                this.avatar_TEO = require("@/assets/images/avatar_TEO.png");
                this.avatar_GIO = require("@/assets/images/avatar_GIO.png");
                this.imgVector = require("@/assets/images/Vector.png");
            } else if (size >= 1250) {
                this.avatar_TEO = require("@/assets/images/avatar_TEO_big.png");
                this.avatar_GIO = require("@/assets/images/avatar_GIO_big.png");
                this.imgVector = require("@/assets/images/Vector_big.png");
            }
        },
    },
    watch: {
        viewPort(value) {
            this.changeLogo(value);
        },
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.onResize);
    },
};
