<template>
    <div class="project" :style="{ backgroundImage: `url(${project.imgUrl})` }">
    </div>
</template>

<script>
export default {
    props: ["project"]
}
</script>

<style lang="scss" scoped>
@import url("@/assets/Style/project.scss");
@import url("@/assets/Style/project.css");
</style>