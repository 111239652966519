<template>
    <div class="background" id="ContactUs"></div>
    <div class="gridcontainer">
        <p v-if="viewPort >= 768" class="title" v-html="title_text"></p>
        <div class="form">
            <p v-if="viewPort < 768" class="title" v-html="title_text"></p>
            <section class="question">
                <p class="q" id="nome">Qual è il tuo nome?</p>
                <inputComponent class="animate__animated" name="Nome" id="name" ref="name" placeholder="Nome"
                    :class="nameInvalid ? 'required animate__shakeX' : ''">
                </inputComponent>
                <!-- <input type="text" name="nameInput" id="nameInput" placeholder="Mario Rossi" class="a"> -->
            </section>
            <section class="question">
                <p class="q">Qual è la tua mail?</p>
                <inputComponent class="animate__animated" name="Mail" id="mail" ref="mail" placeholder="Mail"
                    :class="mailInvalid ? 'required' : ''">
                </inputComponent>
                <!-- <input type="email" name="mailInput" id="mailInput" placeholder="mario@icloud.com" class="a"> -->
            </section>
            <section class="question">
                <p class="q">Qual è il nome della tua azienda?</p>
                <inputComponent class="animate__animated" name="Azienda" id="company" ref="company" placeholder="Azienda"
                    :class="companyInvalid ? 'required' : ''"></inputComponent>
                <!-- <input type="text" name="companyInput" id="companyInput" placeholder="nome azienda" class="a"> -->
            </section>
            <section class="question">
                <p class="q">Per quale servizio ci contatti?</p>
                <inputComponent class="animate__animated" name="Servizio" id="service" ref="service" placeholder="Servizio"
                    :class="serviceInvalid ? 'required' : ''"></inputComponent>
                <!-- <input type="text" name="serviceInput" id="serviceInput" placeholder="Sito nuovo/restyling, logo, grafiche..."
                class="a"> -->
            </section>
            <section class="question">
                <p class="q">Scrivi qui il tuo messaggio...</p>
                <inputComponent class="animate__animated" name="Messaggio" id="messageInput" ref="messageInput"
                    :multiline="true" rows="1" placeholder="Messaggio" :class="messageInputInvalid ? 'required' : ''">
                </inputComponent>
                <!-- <textarea name="messageInput" id="messageInput" rows="8" class="a"
                placeholder="Buongiorno, sarei interessato a..."></textarea> -->
            </section>
        </div>
        <div class="Contatti" v-if="viewPort >= 768">
            <p class="text">Contatti</p>
            <p class="reachers">ethoswebstudio@gmail.com</p>
            <p class="reachers">(+39) 388 1242054</p>
        </div>
        <svg v-if="viewPort >= 768" class="svg" xmlns="http://www.w3.org/2000/svg" width="4" height="208"
            viewBox="0 0 4 208" fill="none">
            <path d="M2 2L2 206" stroke="#444444" stroke-width="3" stroke-linecap="round" />
        </svg>
        <div class="SendMessage">
            <a ref="mailTO" href="" class="mailTO">
                <button class="sphereButton" ref="sendButton" :class="enableSend ? 'enabled' : ''"
                    @click.prevent="setDatiMail">
                    <span>Invia</span>
                </button>
            </a>
        </div>
        <div class="error">
            <p class="errorMessage" ref="errorMessage"></p>
        </div>
    </div>
</template>

<script>
import "animate.css";
import inputComponent from "@/components/inputComponent.vue";
import { commonModule } from '@/commonModule.js';
export default {
    mixins: [commonModule],
    components: {
        inputComponent
    },
    data() {
        return {
            viewPort: window.innerWidth,
            enableSend: false,
            title_text: "Realizziamo un<br/>progetto insieme<span class='pulse'>_</span>",
            nameInvalid: false,
            mailInvalid: false,
            companyInvalid: false,
            serviceInvalid: false,
            messageInputInvalid: false
        }
    },
    methods: {
        setDatiMail() {
            let nome = document.getElementById("name").value
            let mail = document.getElementById("mail").value
            let azienda = document.getElementById("company").value
            let servizio = document.getElementById("service").value
            let messaggio = document.getElementById("messageInput").value
            let data = document.getElementsByClassName('input')
            let missing = 0
            for (var i = 0; i < data.length; i++) {
                let id = data[i].id
                if (data[i].value == '') {
                    document.getElementById(data[i].id).classList.add('required')
                    document.getElementById(data[i].id).classList.add('animate__animated')
                    document.getElementById(data[i].id).classList.add('animate__shakeX')
                    missing += 1
                } else {
                    document.getElementById(data[i].id).classList.remove('required')
                }
                setTimeout(() => {
                    document.getElementById(id).classList.remove('animate__animated')
                    document.getElementById(id).classList.remove('animate__shakeX')
                }, 500);
            }
            if (missing === 0) {
                this.enableSend = true
                this.$refs.mailTO.href = `mailto:ethoswebstudio@gmail.com?&subject=${nome} di ${azienda}&body=Ciao Ethos, sono ${nome} di ${azienda}, vi scrivo per ${servizio}: ${messaggio}. Per contattarmi: ${nome}, mail:${mail}`
                this.$refs.mailTO.click()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import url("@/assets/Style/contactus.scss");
@import url("@/assets/Style/contactus.css");
</style>