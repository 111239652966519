<template>
    <div class="wipBackground">
        <p class="text">Pagina <b>{{ id }}</b> in costruzione</p>
        <p class="info" v-if="data!=''">disponibile entro il <b>{{ data }}</b></p>
        <div class="container">
            <div class="loader"></div>
            <div class="loader"></div>
            <div class="loader"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['id'],
    data() {
        return {
            data: ''
        }
    },
    methods: {
        changeData(idPag) {
            if (idPag === 'Progetti') {
                this.data = ''
            }
        }
    },
    watch: {
        id: function (value) {
            this.changeData(value);
        }
    },
    mounted() {
        this.changeData(this.id)
    }
}
</script>

<style lang="scss" scoped>
.wipBackground {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        font-weight: 500;
    }
}

.text {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 0;
    margin-top: 5rem;
}

.container {
    position: relative;
    min-height: 20rem;
    width: fit-content;

    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 10;
        width: 160px;
        height: 100px;
        margin-left: -80px;
        margin-top: -50px;
        border-radius: 5px;
        background: #1e3f57;
        animation: dot1_ 3s cubic-bezier(0.55, 0.3, 0.24, 0.99) infinite;
    }

    .loader:nth-child(2) {
        z-index: 11;
        width: 150px;
        height: 90px;
        margin-top: -45px;
        margin-left: -75px;
        border-radius: 3px;
        background: #3c517d;
        animation-name: dot2_;
    }

    .loader:nth-child(3) {
        z-index: 12;
        width: 40px;
        height: 20px;
        margin-top: 50px;
        margin-left: -20px;
        border-radius: 0 0 5px 5px;
        background: #6bb2cd;
        animation-name: dot3_;
    }

    @keyframes dot1_ {

        3%,
        97% {
            width: 160px;
            height: 100px;
            margin-top: -50px;
            margin-left: -80px;
        }

        30%,
        36% {
            width: 80px;
            height: 120px;
            margin-top: -60px;
            margin-left: -40px;
        }

        63%,
        69% {
            width: 40px;
            height: 80px;
            margin-top: -40px;
            margin-left: -20px;
        }
    }

    @keyframes dot2_ {

        3%,
        97% {
            height: 90px;
            width: 150px;
            margin-left: -75px;
            margin-top: -45px;
        }

        30%,
        36% {
            width: 70px;
            height: 96px;
            margin-left: -35px;
            margin-top: -48px;
        }

        63%,
        69% {
            width: 32px;
            height: 60px;
            margin-left: -16px;
            margin-top: -30px;
        }
    }

    @keyframes dot3_ {

        3%,
        97% {
            height: 20px;
            width: 40px;
            margin-left: -20px;
            margin-top: 50px;
        }

        30%,
        36% {
            width: 8px;
            height: 8px;
            margin-left: -5px;
            margin-top: 49px;
            border-radius: 8px;
        }

        63%,
        69% {
            width: 16px;
            height: 4px;
            margin-left: -8px;
            margin-top: -37px;
            border-radius: 10px;
        }
    }
}
</style>